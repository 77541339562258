<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <div class="bg-light-info rounded-xl mt-1 py-5">
          <span class="d-flex justify-content-center">
            <span class="text-info font-weight-bold font-size-h6">Bugün</span>
          </span>
          <span class="d-flex justify-content-center mt-5">
            <span class="text-info font-weight-bold font-size-h6">
              <span
                class="
                  svg-icon svg-icon- svg-icon-info
                  d-block
                  my-2
                  d-flex
                  justify-content-center
                "
              >
                19 <inline-svg src="media/svg/icons/Weather/Celcium.svg" />
              </span>
            </span>
          </span>
          <span
            class="
              svg-icon svg-icon-3x svg-icon-info
              d-block
              my-2
              d-flex
              justify-content-center
            "
          >
            <inline-svg src="media/svg/icons/Weather/Sun-fog.svg" />
          </span>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="bg-light-info rounded-xl mt-1 py-5">
          <span class="d-flex justify-content-center">
            <span class="text-info font-weight-bold font-size-h6">Yarın</span>
          </span>
          <span class="d-flex justify-content-center mt-5">
            <span class="text-info font-weight-bold font-size-h6">
              <span
                class="
                  svg-icon svg-icon- svg-icon-info
                  d-block
                  my-2
                  d-flex
                  justify-content-center
                "
              >
                19 <inline-svg src="media/svg/icons/Weather/Celcium.svg" />
              </span>
            </span>
          </span>
          <span
            class="
              svg-icon svg-icon-3x svg-icon-info
              d-block
              my-2
              d-flex
              justify-content-center
            "
          >
            <inline-svg src="media/svg/icons/Weather/Cloud-wind.svg" />
          </span>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="bg-light-info rounded-xl mt-1 py-5">
          <span class="d-flex justify-content-center">
            <span class="text-info font-weight-bold font-size-h6"
              >Çarşamba</span
            >
          </span>
          <span class="d-flex justify-content-center mt-5">
            <span class="text-info font-weight-bold font-size-h6">
              <span
                class="
                  svg-icon svg-icon- svg-icon-info
                  d-block
                  my-2
                  d-flex
                  justify-content-center
                "
              >
                19 <inline-svg src="media/svg/icons/Weather/Celcium.svg" />
              </span>
            </span>
          </span>
          <span
            class="
              svg-icon svg-icon-3x svg-icon-info
              d-block
              my-2
              d-flex
              justify-content-center
            "
          >
            <inline-svg src="media/svg/icons/Weather/Cloudy.svg" />
          </span>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="bg-light-info rounded-xl mt-1 py-5">
          <span class="d-flex justify-content-center">
            <span class="text-info font-weight-bold font-size-h6"
              >Perşembe</span
            >
          </span>
          <span class="d-flex justify-content-center mt-5">
            <span class="text-info font-weight-bold font-size-h6">
              <span
                class="
                  svg-icon svg-icon- svg-icon-info
                  d-block
                  my-2
                  d-flex
                  justify-content-center
                "
              >
                19 <inline-svg src="media/svg/icons/Weather/Celcium.svg" />
              </span>
            </span>
          </span>
          <span
            class="
              svg-icon svg-icon-3x svg-icon-info
              d-block
              my-2
              d-flex
              justify-content-center
            "
          >
            <inline-svg src="media/svg/icons/Weather/Day-rain.svg" />
          </span>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="bg-light-info rounded-xl mt-1 py-5">
          <span class="d-flex justify-content-center">
            <span class="text-info font-weight-bold font-size-h6">Cuma</span>
          </span>
          <span class="d-flex justify-content-center mt-5">
            <span class="text-info font-weight-bold font-size-h6">
              <span
                class="
                  svg-icon svg-icon- svg-icon-info
                  d-block
                  my-2
                  d-flex
                  justify-content-center
                "
              >
                19 <inline-svg src="media/svg/icons/Weather/Celcium.svg" />
              </span>
            </span>
          </span>
          <span
            class="
              svg-icon svg-icon-3x svg-icon-info
              d-block
              my-2
              d-flex
              justify-content-center
            "
          >
            <inline-svg src="media/svg/icons/Weather/Cloud-sun.svg" />
          </span>
        </div>
      </div>
    </div>
    <hr class="my-10" />
    <div class="row">
      <div class="col-lg-4">
        <b-card
          :img-src="getImage"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-2 w-100 h-100 bg-light-primary"
        >
          <div><strong>Düzenlendi: </strong>23.12.2019</div>
          <div><strong>Sınıflandırma: </strong>Yerleşim</div>
          <div><strong>PV Kapasitesi: </strong>960.00 kW</div>
          <div>
            <strong>Adres: </strong>Üzümlü, Unnamed Road, 44000
            Battalgazi/Malatya, Türkiye
          </div>
        </b-card>
      </div>
      <div class="col-lg-3">
        <div
          class="
            bg-light-primary
            rounded-xl
            py-5
            h-100
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <div>
            <div class="font-weight-bold font-size-h6 text-center">
              Kapasite Kullanım Oranı
            </div>
            <apexchart
              class="card-rounded-bottom"
              :options="chartOptions1"
              :series="series"
              type="radialBar"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="row h-100">
          <div class="col-lg-12 d-flex justify-content-between flex-column">
            <div class="bg-light-warning rounded-xl py-5">
              <span
                class="
                  svg-icon svg-icon-3x svg-icon-warning
                  d-block
                  my-2
                  d-flex
                  justify-content-center
                "
              >
                <inline-svg src="media/svg/icons/Electric/Highvoltage.svg" />
              </span>
              <span class="d-flex justify-content-center">
                <span class="text-warning font-weight-bold font-size-h6"
                  >Bugünün üretimi</span
                >
              </span>
              <span class="d-flex justify-content-center mt-5">
                <span class="text-warning font-weight-bold font-size-h6"
                  >1904.00 kWh</span
                >
              </span>
            </div>
            <div class="bg-light-info rounded-xl mt-7 py-5">
              <span
                class="
                  svg-icon svg-icon-3x svg-icon-info
                  d-block
                  my-2
                  d-flex
                  justify-content-center
                "
              >
                <inline-svg src="media/svg/icons/Electric/Highvoltage.svg" />
              </span>
              <span class="d-flex justify-content-center">
                <span class="text-info font-weight-bold font-size-h6"
                  >Toplam Üretim</span
                >
              </span>
              <span class="d-flex justify-content-center mt-5">
                <span class="text-info font-weight-bold font-size-h6"
                  >2409794.70 kWh</span
                >
              </span>
            </div>
          </div>
          <!--                    <div class="col-lg-6">-->
          <!--                       <div class=" bg-light-danger rounded-xl py-5">-->
          <!--                           <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2 d-flex justify-content-center">-->
          <!--                          <inline-svg src="media/svg/icons/Shopping/Dollar.svg" />-->
          <!--                        </span>-->
          <!--                           <span class="d-flex justify-content-center">-->
          <!--                            <span class="text-danger font-weight-bold font-size-h6">Bugün Gelir</span>-->
          <!--                        </span>-->
          <!--                           <span class="d-flex justify-content-center mt-5">-->
          <!--                            <span class="text-danger font-weight-bold font-size-h6">253.23 USD</span>-->
          <!--                        </span>-->
          <!--                       </div>-->
          <!--                    </div>-->
          <!--                    <div class="col-lg-6">-->
          <!--                        <div class=" bg-light-success rounded-xl mt-7 py-5">-->
          <!--                           <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2 d-flex justify-content-center">-->
          <!--                          <inline-svg src="media/svg/icons/Shopping/Money.svg" />-->
          <!--                        </span>-->
          <!--                            <span class="d-flex justify-content-center">-->
          <!--                            <span class="text-success font-weight-bold font-size-h6">Toplam Kazanç</span>-->
          <!--                        </span>-->
          <!--                            <span class="d-flex justify-content-center mt-5">-->
          <!--                            <span class="text-success font-weight-bold font-size-h6">320502.70 USD</span>-->
          <!--                        </span>-->
          <!--                        </div>-->
          <!--                    </div>-->
        </div>
      </div>
    </div>
    <hr class="my-10" />
    <div class="row">
      <div class="col-lg-12 h-100">
        <div v-if="chartType === 1">
          <div class="mb-4 d-lg-flex justify-content-lg-between">
            <div class="text-center mt-5">
              <button class="btn btn-sm btn-success mr-2">Full - 1</button>
              <button class="btn btn-sm btn-success mr-2">Excel</button>
              <button
                @click="chartType = 1"
                class="btn btn-sm btn-success mr-2"
              >
                Güç Üretimi
              </button>
              <button
                @click="chartType = 0"
                class="btn btn-sm btn-success mr-2"
              >
                Güç Üretimi & Kazanç
              </button>
            </div>
            <div role="group" class="text-center mt-5">
              <b-form-input
                id="type-date1"
                type="date"
                class="d-inline-block"
                style="max-width: 150px"
                v-model="today"
              ></b-form-input>
            </div>
          </div>
          <StatsWidget70 class="p-5"></StatsWidget70>
        </div>
        <div v-else>
          <div class="mb-4 d-lg-flex justify-content-lg-between">
            <div class="text-center mt-5">
              <button class="btn btn-sm btn-success mr-2">Full - 0</button>
              <button class="btn btn-sm btn-success mr-2">Excel</button>
              <button
                @click="chartType = 1"
                class="btn btn-sm btn-success mr-2"
              >
                Güç Üretimi
              </button>
              <button
                @click="chartType = 0"
                class="btn btn-sm btn-success mr-2"
              >
                Güç Üretimi & Kazanç
              </button>
            </div>
            <div role="group" class="text-center mt-5">
              <b-form-input
                id="type-date2"
                type="date"
                class="d-inline-block"
                style="max-width: 150px"
              ></b-form-input>
            </div>
          </div>
          <StatsWidget71 class="p-5"></StatsWidget71>
        </div>
      </div>
    </div>
    <hr class="my-10" />
    <div class="row">
      <div class="col-lg-12">
        <div
          v-if="tableType === 2"
          class="bg-light-danger"
          style="padding: 0 15px"
        >
          <div class="row">
            <div class="col-12">
              <div class="font-weight-bold text-center text-danger">
                Invertor 1
              </div>
              <div class="d-flex justify-content-between">
                <button @click="tableType = 1" class="btn btn-outline-success">
                  Tabloyu Göster
                </button>
                <button @click="tableType = 3" class="btn btn-outline-success">
                  Grafiği Göster
                </button>
              </div>
            </div>
            <div class="col-lg-4">
              <ul>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted"><strong>Model</strong></div>
                  <div class="col-6">GW80K-MT</div>
                  <div class="col-2"></div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted"><strong>S/N</strong></div>
                  <div class="col-6">9080KMTU19AP0023</div>
                  <div class="col-2"></div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted"><strong>Checkcode</strong></div>
                  <div class="col-6">053596</div>
                  <div class="col-2"></div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted"><strong>Kapasite</strong></div>
                  <div class="col-6">80</div>
                  <div class="col-2">kW</div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted"><strong>Bağlı</strong></div>
                  <div class="col-6">15.11.2019 22:16:46</div>
                  <div class="col-2"></div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted"><strong>Güç</strong></div>
                  <div class="col-6">0.747</div>
                  <div class="col-2">kW</div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted">
                    <strong>Çıkış Gerilimi</strong>
                  </div>
                  <div class="col-6">225.0/227.2/226.6</div>
                  <div class="col-2">V</div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted"><strong>AC akım</strong></div>
                  <div class="col-6">1.6/1.1/1.4</div>
                  <div class="col-2">A</div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted">
                    <strong>AC Frekans</strong>
                  </div>
                  <div class="col-6">49.97/49.97/49.97</div>
                  <div class="col-2">Hz</div>
                </li>
              </ul>
            </div>
            <div
              class="col-lg-4 d-flex justify-content-center align-items-center"
            >
              <div class="w-100">
                <div class="d-flex justify-content-center mb-5">
                  <div
                    class="bg-light-success w-100 text-center p-10"
                    style="border-radius: 10px; border: 1px solid #fff"
                  >
                    <div>Bugünün Üretimi</div>
                    <div style="font-size: 50px">544.7</div>
                    <div>kWh</div>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-12">
                    <button class="btn btn-outline-secondary mb-5">
                      Çevrimdışı
                    </button>
                    <div>Son Yenileme Zamanı: 07.04.2021 19:26</div>
                  </div>
                  <div class="col-6">
                    <span
                      class="
                        svg-icon svg-icon-3x svg-icon-warning
                        d-block
                        my-2
                        d-flex
                        justify-content-center
                      "
                    >
                      <inline-svg
                        src="media/svg/icons/Electric/Highvoltage.svg"
                      />
                    </span>
                    <div>Toplam Üretim</div>
                    <div>209258.6 kWh</div>
                  </div>
                  <div class="col-6">
                    <span
                      class="
                        svg-icon svg-icon-3x svg-icon-warning
                        d-block
                        my-2
                        d-flex
                        justify-content-center
                      "
                    >
                      <inline-svg src="media/svg/icons/Home/Clock.svg" />
                    </span>
                    <div>Toplam Saat</div>
                    <div>209258.6 s</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <ul>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted"><strong>Model</strong></div>
                  <div class="col-6">GW80K-MT</div>
                  <div class="col-2"></div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted"><strong>S/N</strong></div>
                  <div class="col-6">9080KMTU19AP0023</div>
                  <div class="col-2"></div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted"><strong>Checkcode</strong></div>
                  <div class="col-6">053596</div>
                  <div class="col-2"></div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted"><strong>Kapasite</strong></div>
                  <div class="col-6">80</div>
                  <div class="col-2">kW</div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted"><strong>Bağlı</strong></div>
                  <div class="col-6">15.11.2019 22:16:46</div>
                  <div class="col-2"></div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted"><strong>Güç</strong></div>
                  <div class="col-6">0.747</div>
                  <div class="col-2">kW</div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted">
                    <strong>Çıkış Gerilimi</strong>
                  </div>
                  <div class="col-6">225.0/227.2/226.6</div>
                  <div class="col-2">V</div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted"><strong>AC akım</strong></div>
                  <div class="col-6">1.6/1.1/1.4</div>
                  <div class="col-2">A</div>
                </li>
                <li class="row border-bottom my-2">
                  <div class="col-4 text-muted">
                    <strong>AC Frekans</strong>
                  </div>
                  <div class="col-6">49.97/49.97/49.97</div>
                  <div class="col-2">Hz</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-else-if="tableType == 3">
          <div class="row">
            <div class="col-12">
              <div class="font-weight-bold text-center text-danger">
                Invertor 1
              </div>
              <div class="d-flex justify-content-between">
                <button @click="tableType = 1" class="btn btn-outline-success">
                  Tabloyu Göster
                </button>
                <button @click="tableType = 3" class="btn btn-outline-success">
                  Grafiği Göster
                </button>
              </div>
            </div>
            <div class="col-12">
              <div v-if="invertorType === 1">
                <div class="mb-4 d-lg-flex justify-content-lg-between">
                  <div class="text-center mt-5">
                    <button class="btn btn-sm btn-success mr-2">
                      Full - 1
                    </button>
                    <button class="btn btn-sm btn-success mr-2">Excel</button>
                    <button
                      @click="invertorType = 1"
                      class="btn btn-sm btn-success mr-2"
                    >
                      Güç Üretimi
                    </button>
                    <button
                      @click="invertorType = 0"
                      class="btn btn-sm btn-success mr-2"
                    >
                      Güç Üretimi & Kazanç
                    </button>
                  </div>
                  <div role="group" class="text-center mt-5">
                    <b-form-input
                      id="type-date3"
                      type="date"
                      class="d-inline-block"
                      style="max-width: 150px"
                    ></b-form-input>
                  </div>
                </div>
                <StatsWidget72 class="p-5"></StatsWidget72>
              </div>
              <div v-else>
                <div class="mb-4 d-lg-flex justify-content-lg-between">
                  <div class="text-center mt-5">
                    <button class="btn btn-sm btn-success mr-2">
                      Full - 0
                    </button>
                    <button class="btn btn-sm btn-success mr-2">Excel</button>
                    <button
                      @click="invertorType = 1"
                      class="btn btn-sm btn-success mr-2"
                    >
                      Güç Üretimi
                    </button>
                    <button
                      @click="invertorType = 0"
                      class="btn btn-sm btn-success mr-2"
                    >
                      Güç Üretimi & Kazanç
                    </button>
                  </div>
                  <div role="group" class="text-center mt-5">
                    <b-form-input
                      id="type-date4"
                      type="date"
                      class="d-inline-block"
                      style="max-width: 150px"
                    ></b-form-input>
                  </div>
                </div>
                <StatsWidget73 class="p-5"></StatsWidget73>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <v-card>
            <v-card-title>
              <div>
                <small
                  class="svg-icon svg-icon-2x svg-icon-success mr-2"
                  style="
                    border: 1px solid #ddd;
                    padding: 5px 10px;
                    border-radius: 5px;
                  "
                >
                  <inline-svg src="media/svg/icons/General/star.svg" />
                  Çalışma
                </small>
                <small
                  class="svg-icon svg-icon-2x svg-icon-warning mr-2"
                  style="
                    border: 1px solid #ddd;
                    padding: 5px 10px;
                    border-radius: 5px;
                  "
                >
                  <inline-svg src="media/svg/icons/General/star.svg" />
                  Bekleme
                </small>
                <small
                  class="svg-icon svg-icon-2x svg-icon-danger mr-2"
                  style="
                    border: 1px solid #ddd;
                    padding: 5px 10px;
                    border-radius: 5px;
                  "
                >
                  <inline-svg src="media/svg/icons/General/star.svg" />
                  Hata
                </small>
                <small
                  class="svg-icon svg-icon-2x svg-icon-secondary"
                  style="
                    border: 1px solid #ddd;
                    padding: 5px 10px;
                    border-radius: 5px;
                  "
                >
                  <inline-svg src="media/svg/icons/General/star.svg" />
                  Çevrimdışı
                </small>
              </div>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="desserts"
              :search="search"
              @click:row="rowClick($event)"
              style="cursor: pointer"
            >
              <template v-slot:[`item.status`]="{ item }">
                <small
                  v-if="item.status == 1"
                  class="svg-icon svg-icon-2x svg-icon-success"
                >
                  <inline-svg
                    :title="'deneme'"
                    :src="'media/svg/icons/General/star.svg'"
                  />
                </small>
                <small
                  v-else-if="item.status == 2"
                  class="svg-icon svg-icon-2x svg-icon-warning"
                >
                  <inline-svg :src="'media/svg/icons/General/star.svg'" />
                </small>
                <small
                  v-else-if="item.status == 3"
                  class="svg-icon svg-icon-2x svg-icon-danger"
                >
                  <inline-svg :src="'media/svg/icons/General/star.svg'" />
                </small>
                <small v-else class="svg-icon svg-icon-2x svg-icon-secondary">
                  <inline-svg :src="'media/svg/icons/General/star.svg'" />
                </small>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsWidget70 from "@/view/content/widgets/stats/Widget7.vue";
import StatsWidget71 from "@/view/content/widgets/stats/Widget71.vue";
import StatsWidget72 from "@/view/content/widgets/stats/Widget72.vue";
import StatsWidget73 from "@/view/content/widgets/stats/Widget73.vue";
import { mapGetters } from "vuex";

export default {
  name: "detail_content",
  components: {
    StatsWidget70,
    StatsWidget71,
    StatsWidget72,
    StatsWidget73,
  },
  data() {
    return {
      today: "2021-04-09",
      tableType: 1,
      chartType: 1,
      invertorType: 1,
      search: "Inv",
      headers: [
        {
          text: "Invertör",
          align: "left",
          sortable: false,
          value: "invertor",
        },
        { text: "Durum", value: "status" },
        { text: "S/N", value: "sn" },
        { text: "Güç", value: "power" },
        { text: "Günlük", value: "daily" },
        { text: "Aylık", value: "monthly" },
        { text: "Genel Toplam", value: "total" },
      ],
      desserts: [
        {
          invertor: "Inv 1",
          status: 1,
          sn: "9080KMTU19AP0022",
          power: "0.00kW",
          daily: "0.00kW",
          monthly: "0.00kW",
          total: "2380790.60kWh",
        },
        {
          invertor: "Inv 2",
          status: 2,
          sn: "9080KMTU19AP0022",
          power: "0.00kW",
          daily: "0.00kW",
          monthly: "0.00kW",
          total: "2380790.60kWh",
        },
        {
          invertor: "Inv 3",
          status: 3,
          sn: "9080KMTU19AP0022",
          power: "0.00kW",
          daily: "0.00kW",
          monthly: "0.00kW",
          total: "2380790.60kWh",
        },
        {
          invertor: "Inv 4",
          status: 4,
          sn: "9080KMTU19AP0022",
          power: "0.00kW",
          daily: "0.00kW",
          monthly: "0.00kW",
          total: "2380790.60kWh",
        },
        {
          invertor: "Inv 5",
          status: 1,
          sn: "9080KMTU19AP0022",
          power: "0.00kW",
          daily: "0.00kW",
          monthly: "0.00kW",
          total: "2380790.60kWh",
        },
      ],
      chartOptions1: {},
      series: [((500 * 100) / 900).toFixed(0)],
      weather: {},
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    getImage() {
      return process.env.BASE_URL + "media/images/ges1.jpg";
    },
  },
  mounted() {
    // reference; kt_stats_widget_7_chart
    this.chartOptions1 = {
      chart: {
        height: 200,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700",
            },
            value: {
              color: this.layoutConfig("colors.gray.gray-700"),
              fontSize: "30px",
              fontWeight: "700",
              offsetY: 12,
              show: true,
            },
          },
          track: {
            background: this.layoutConfig("colors.theme.light.success"),
            strokeWidth: "100%",
          },
        },
      },
      colors: [this.layoutConfig("colors.theme.base.success")],
      stroke: {
        lineCap: "round",
      },
      labels: ["Progress"],
    };
  },
  methods: {
    rowClick(event) {
      this.tableType = 2;
    },
  },
};
</script>

<style scoped>
</style>
