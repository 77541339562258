<template>
  <!--begin::Stats Widget 11-->
  <div class="card card-custom gutter-b">
    <!--begin::Body-->
    <div class="card-body p-0">
      <div
        class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
      >
        <span class="symbol  symbol-50 symbol-light-success mr-2">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-xl svg-icon-success">
              <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg" />
            </span>
          </span>
        </span>
        <div class="d-flex flex-column text-right">
          <span class="text-dark-75 font-weight-bolder font-size-h3">Güç Üretimi</span>
        </div>
      </div>
      <!--begin::Chart-->
      <apexchart
        class="card-rounded-bottom"
        :options="chartOptions"
        :series="series"
        type="area"
        width="100%"
      ></apexchart>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Stats Widget 11-->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "widget-72",
  data() {
    return {
      chartOptions: {},
      series: [
        {
          name: "Üretim",
          data: [0, 0, 0, 0, 0, 0, 0,0, 400, 450, 500, 600, 780, 800,900, 700, 500, 400, 350, 100, 0,0, 0, 0]
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    // reference; kt_stats_widget_7_chart
    this.chartOptions = {
        chart: {
            type: 'area',
            height: 300,
            toolbar: {
                show: true
            }
        },
        series: [{
            name: "Üretim",
            data: [0, 0, 0, 0, 0, 0, 0,0, 400, 450, 500, 600, 780, 800,900, 700, 500, 400, 350, 100, 0,0, 0, 0]
        }],
        xaxis: {
            categories: ["00:00", "01:00", "02:00", "03:00","04:00", "05:00", "06:00", "07:00","08:00", "09:00", "10:00", "11:00","12:00", "13:00", "14:00", "15:00","16:00", "17:00", "18:00", "19:00","20:00", "21:00", "22:00", "23:00"],
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: true
            },
            labels: {
                show: true,
                style: {
                    colors: this.layoutConfig("colors.gray.gray-500"),
                    fontSize: "12px",
                    fontFamily: this.layoutConfig("font-family")
                }
            },
            crosshairs: {
                show: true,
                position: "front",
                stroke: {
                    color: this.layoutConfig("colors.gray.gray-300"),
                    width: 1,
                    dashArray: 3
                }
            },
            tooltip: {
                enabled: false
            }
        },
        yaxis:{
            min: 0,
            max: 990,
            show: true,
            labels: {
                show: true,
                style: {
                    colors: this.layoutConfig("colors.gray.gray-500"),
                    // fontSize: "12px",
                    fontFamily: this.layoutConfig("font-family")
                }
            }
        },

        colors: [this.layoutConfig("colors.theme.light.success")],
        markers: {
            colors: [this.layoutConfig("colors.theme.light.success")],
            strokeColor: [this.layoutConfig("colors.theme.base.success")],
            strokeWidth: 3
        },

        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: "smooth",
            show: true,
            width: 3,
            colors: [this.layoutConfig("colors.theme.base.success")]
        }

    };
  }
};
</script>
