<template>
  <!--begin::Stats Widget 11-->
  <div class="card card-custom gutter-b">
    <!--begin::Body-->
    <div class="card-body p-0">
      <div class="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
        <div class="d-flex flex-column text-right">
          <span class="text-dark-75 font-weight-bolder font-size-h3">{{title}}</span>
        </div>
      </div>
      <!--begin::Chart-->
      <apexchart
        class="card-rounded-bottom"
        :options="chartOptions"
        :series="series"
        type="bar"
        width="100%"
      ></apexchart>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Stats Widget 11-->
</template>

<script>
import { mapGetters } from "vuex";
import {GET_DAILY_INVERTER_DATA} from "@/core/services/store/inverters.module";

export default {
    name: "widget-71",
    props: ['title'],
    data() {
        return {
            chartOptions: {
                xaxis:{
                    categories: [],
                    title: {
                        text: "Tarih",
                        style: {
                            color: "#0D47A1"
                        }
                    }
                }
            },
            series: [
                {
                  name: "Üretim",
                  data: []
                }
            ],
        };
    },
    computed: {
    ...mapGetters(["layoutConfig",'getInverterCategories','getInverterSeries'])
    },
    mounted() {
        this.series[0].data = this.$store.getters.getInverterSeries,
        this.chartOptions = {
            xaxis:{
                categories: this.$store.getters.getInverterCategories,
                title: {
                    text: "Tarih",
                    style: {
                        color: "#0D47A1"
                    }
                }
            },
            chart: {
                height: 350,
                    type: "bar",
                    stacked: false,
                    zoom: {
                    enabled: true,
                        type: 'x',
                        autoScaleYaxis: true,
                        zoomedArea: {
                        fill: {
                            color: '#90CAF9',
                                opacity: 0.4
                        },
                        stroke: {
                            color: '#0D47A1',
                                opacity: 0.4,
                                width: 1
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ["#FF1654", "#247BA0"],
            stroke: {
                width: [4, 4]
            },
            plotOptions: {
                bar: {
                    columnWidth: "50%"
                }
            },
            yaxis: [
                {
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true,
                        color: "#FF1654"
                    },
                    labels: {
                        style: {
                            colors: "#FF1654"
                        }
                    },
                    title: {
                        text: "Üretim",
                        style: {
                            color: "#FF1654"
                        }
                    }
                },
            ],
            tooltip: {
                shared: false,
                    intersect: true,
                    x: {
                    show: false
                }
            },
            legend: {
                horizontalAlign: "left",
                    offsetX: 40
            }
        }
    }
};
</script>
